#containerCadastrar{
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: white;

    height: max-content;
}
#containerCadastrar .allFieldsCadastrar{
    display: flex;
    align-items: center;
    background-color: var(--ContainerInterno);
    border-radius: 10px;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 600px;
    margin: 0 20px;
    gap: 10px;
}

#containerCadastrar .input::placeholder {
    color: rgba(246, 246, 246, 0.536);
}
#containerCadastrar .field{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
}
#containerCadastrar .input {
    background-color: transparent;
    width: 150px;
    text-align: center;
    height: 20px;
    border: none;
    border-bottom: 1px solid gray;
}

#containerCadastrar .btnCadastrar:disabled, #colaboradores .btnCadastrar:disabled:hover{
    background-image: none;
    background-color: gray;
}
