.field {
    font-size: 14px;
}

.field input {
    font-size: 13px;
    width: 80px;
}

.field textarea {
    font-size: 13px;
    width: 80px;
}