#globalDatePicker{
    width: 400px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: var(--schiefBlue);
}
#globalDatePicker select{
    text-align: center;
    padding: 3px;
}

.select{
    display: flex;
    flex-direction: column;
}
#globalDatePicker .firstSelector{
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    gap: 15px;
}

#globalDatePicker .container{
    display: flex;
    justify-content: center;
}

#globalDatePicker .field{
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    margin: 5px;
}

#globalDatePicker .label {
    font-size: 13px;
    margin-bottom: 5px;
    color: white;
}
#globalDatePicker button {
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    
}

#globalDatePicker .maxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}