#addRealValue {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid gray;
    background-color: var(--schiefBlue);
    z-index: 5555;
}

.closeIcon{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: white;
}

#addRealValue .inputsContainer{
    display: flex;
    margin-bottom: 10px;
}

#addRealValue .dataField{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 10px;
}
#addRealValue .field{
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#addRealValue .input, #addRealValue .textarea {
    padding: 10px 10px;
    margin: 0 3px;
    border-radius: 5px;
}

#addRealValue .title {
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
}

#addRealValue .submitBtn{
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
}

#addRealValue .label{
    color: white !important;
}