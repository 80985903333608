#despesas .valuesContainer{
    /* background-color: var(--ContainerInterno); */
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    margin: 20px 0;
    max-width: 90vw;
    height: max-content;
}

#despesas .section{
    margin: 20px 0;
}
#despesas .topBtns{
    display: flex;
    gap: 10px;
}
#despesas .recurrentSection{
    background-color: var(--schiefBlue);
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    width: 600px;
}
#despesas .sectionTitle{
    color: var(--TituloContainerInterno);
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

#despesas .topLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#despesas .periodo {
    background-color: orange;
    border-radius: 5px;
    height: 30px;
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;
    border: none;
}

#despesas .section_title{
    color: var(--schiefGray);
    margin: 10px 0 5px;
}

.datePickerContainer{
    display: flex;
    gap: 10px;
    /* text-align: center; */
    padding: 10px;
    border-radius: 5px;
    background-color: var(--TabelaFundo);
    align-items: center;
    margin-bottom: 10px;
}

.EDIcons {
    cursor: pointer;
}