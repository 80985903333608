#login {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
}
#login .title{
    color: var(--schiefBlue);
    font-size: 25px;
    font-weight: 700;
    align-self: center;
    margin: 40px;
}
#login .header_container{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
}
#login .login_card{
    display: flex;
    flex-direction: column;
    background-color: var(--schiefGray);
    box-shadow: 0px 0px 2px 1px black;
    border-radius: 20px;
    width: 300px;
    height: 300px;
    color: white;
}

#login .google_button{
    /* margin: 90px 0; */
    align-self: center;
}