.container{ 
    background-color: rgba(255, 255, 0, 0.474);
    display: flex;
    padding: 10px;
    gap: 50px;
    font-size: 14px;
}

.container td{
    border: none
}



.field input, .field textarea {
    width: 80%;
}

.buttonsField{
    display: flex;
    gap: 10px;
}
.buttonsField input[type='button']{
    color: white;   
    border: none;
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;


    background-color: var(--schiefBlue);

    &:hover{
        background-color: var(--schiefRed);

    }
}
