#paymentsForm {
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgba(128, 128, 128, 0.5);
    align-items: center;
    justify-content: center;
    color: black;
    gap: 10px;
}

#paymentsForm .field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 10px;
}
#paymentsForm input{
    text-align: center;
}
#paymentsForm .remType{
    flex-direction: row;
    border: 1px solid rgba(128, 128, 128, 0.4);
    padding: 10px;
    gap: 10px;
}

#paymentsForm .remType div{
    display: flex;
    align-items: center;
}