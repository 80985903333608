.container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.title{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
}

.value {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    color: var(--schiefBlue);
    padding: 10px 5px;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
}
.value .label{
    font-weight: 400;
}
.value .span{
    font-weight: 300;
}
.field {
    max-width: 80%;
}
.value:last-child{
    border: none;
}
.valuesContainer{
    border: 1px solid rgb(218, 218, 218);
}

.moneyBtnContainer {
    display: flex;
}

.container table {
    width: 100%;
}

.container table, .container tr {
    /* width: 100%; */
    border: solid 1px black;
}
.container tr {
    text-align: center;
}

.editableRow {
    width: 10px;
}

.moneyBtn{
    padding: 3px;
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: all .3s;
    position: relative;
}
.moneyBtn:hover{
    color: var(--schiefRed);
}
.moneyBtn:hover .tooltip {
    display: inline;
}

.tooltip{
    font-size: 13px;
    display: none;
    position: absolute;
    top: -15px;
    right: -10px;
}

.paymentFormContainer{
    background-color: var(--schiefBlue);
    color: white;
    position: absolute;
    top: 0;
    left: -500px;
    transform: translateY(-50%);
    z-index: 99999;
}

.closeForm{
    position: absolute;
    width: 100%;
    height: 500%;
    background-color: rgba(167, 167, 167, 0.212);
    z-index: 9999;
    top: 0;
    left: 0;
}