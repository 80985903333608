.container {
    display: flex;
    flex-direction: column;
}

.title{
    font-size: 20px;
    align-self: flex-start;
}

.description {
    font-size: 14px;
}

.topContainer{
    display: flex;
    justify-content: space-between;
}


.formContainer{
    background-color: var(--schiefBlue);
    color: white;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 99999;
}
