#homeBtns {
    display: flex;
    flex-direction: column;
    background-color: var(--HomeBtnsFundo);
    align-items: center;
    justify-content: center;
    padding: 0 5px 5px;
    width: 200px;
    text-align: center;
    height: 80px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgb(51, 51, 51);
    cursor: pointer;
    border: 1px solid transparent;
    gap: 10px;
}
#homeBtns:hover {
    border-color: var(--schiefBlue);
}
#homeBtns .icon {
    color: var(--HomeBtnsIcon);
}
#homeBtns .title {
    color: var(--HomeBtnsTitle);
}