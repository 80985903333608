#urvtable {
    background-color: var(--schiefGray);
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
}

#urvtable tbody {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
}
#urvtable th {
    padding: 5px;
    border: none !important;
}

#urvtable tr{
    border-collapse: collapse;
}
#urvtable td {
    padding: 5px;
}
#urvtable .title{
    font-weight: bold;
    font-size: 18px;
    color: var(--schiefBlue);
}
#urvtable .label {
    color: var(--schiefBlue2);
    font-weight: bold;
}

#urvtable .colabTBody{
    flex-direction: column;
}
#urvtable .colabTBody .headTitles{
background-color: var(--schiefRed);
}
#urvtable .colabTBody .headTitles td{
color: var(--schiefGray);
}
#urvtable .colabTBody tr{
    margin-left: 20px;
    border: 1px solid var(--schiefRed);
    max-width: 800px;
}
#urvtable .colabTBody td{
    width: 120px;
    text-align: center !important;
}

#urvtable .colabTBody .nome {
    width: 200px;
}