.tableContainer{
    padding: 10px;
    margin: 20px 0;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.topButtonsContainer{
    margin-bottom: 20px;
}
.tableContainer h2{
    color: var(--schiefGray);
    border-radius: 5px;
    font-size: 20px;
    background-color: var(--schiefBlue);
    padding: 5px 20px;
    margin: 10px 0;
}
.tableContainer h3{
    color: var(--schiefGray);
    font-size: 15px;
    align-self: flex-start;
    background-color: var(--schiefRed);
    padding: 5px 20px;
}