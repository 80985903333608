@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
} */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

:root{
  --schiefRed: #c74242; 
  --schiefRed2: #C41619; 
  --schiefRed3: #EA4335; 
  --schiefBlue: #003263;
  --schiefBlue2: #142C57;
  --schiefGray: #eeeeee;

  --HeaderFundo: #003263;
  --NavBarFundo: #003263;
  --NavBarBtnsFundo: #003263;
  --NavBarBtnsTexto: #eeeeee;
  --TopBarFundo: #003263;

  --TituloDaPagina: #003263;
  --Subtitulo: #eeeeee;
  --mainBackground: #eeeeee;

  --ContainerInterno: #003263;
  --TituloContainerInterno: #EA4335;

  --GraficosFundo: white;
  --GraficosTitulo: #EA4335;

  --BtnFundo: #003263;
  --BtnExternoFundo: #EA4335;
  --BtnExternoTexto: #eeeeee;
  --BtnTexto: #eeeeee;

  --TabelaFundo: #eeeeee;
  --TabelaTexto: black;
  --TabelaTituloFundo: white;
  --TabelaTitulo: #EA4335;
  --TabelaLinhaFundo: #eeeeee;

  --HomeBtnsFundo: white;
  --HomeBtnsIcon: #EA4335;
  --HomeBtnsTitle: #003263;
}

.topContent{
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100vw;
  height: max-content;
  background-color: var(--TopBarFundo);
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  background-color: var(--mainBackground);
}

html, body, #root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background-color: var(--mainBackground);
}