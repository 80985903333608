.container {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin: 15px;
}

.container input, .container label {
    padding: 3px 5px;
}

.container input{
    text-transform: uppercase;
}