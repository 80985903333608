#expensesTable{
    width: 100%;
    border-collapse: collapse;
    /* background-color: var(--TabelaTituloFundo); */
}
#expensesTable  td{
    border: 1px solid gray;
}
#expensesTable th {
    color: var(--TabelaTitulo);
    /* background-color: var(--TabelaTituloFundo); */
    padding: 20px;
    font-weight: bold;
}
#expensesTable tr {
    background-color: var(--TabelaLinhaFundo);
    color: var(--TabelaTexto);
}
#expensesTable .valueRow{
    height: 20px;
}
#expensesTable .obs{
    font-size: 13px;
    font-style: italic;
    position: absolute;
    white-space: pre-line;
}
#expensesTable .value {
    padding: 5px;
    text-align: center;
}

#expensesTable .EDField{
    display: flex;
    align-items: center;
}
#expensesTable .EDIcons{
    font-size: 20px;
    margin: 0 3px;
    cursor: pointer !important;
}

#expensesTable .totalRow{
    font-weight: 700;
}

#expensesTable .labels{
    font-weight: bold;
}

.paid_btn{
    background-color: rgba(80, 255, 80, 0.8) !important;
    color: var(--schiefBlue) !important;
    min-width: 85px;
}

.pending_btn{
    background-color: rgba(255, 80, 80, 1) !important;
}

