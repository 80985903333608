#metricas .containerByUnity{
    flex-direction: column;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    border-collapse: collapse;
    order: 1;
}
#metricas .titleByUnity{
    color: var(--schiefGray);
    font-size: 18px;
    font-weight: bold;
}
#metricas .uda_order{
    order: 2;
}
#metricas .udf_order{
    order: 3;
}
#metricas .escritorio_order{
    order: 4;
}
#metricas .tableAndChartContainer{
    display: flex;
    align-items: center;
}
#metricsTable{
    border-collapse: collapse !important;
    min-width: 400px;
    max-width: max-content;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}
#metricsTable th{
    background-color: var(--TabelaTituloFundo);
    color: var(--TabelaTitulo);
    text-align: center;
    font-weight: bold;
    padding: 20px 0;
}