@media (max-width: 700px ) {
    #containerCadastrar {
        flex-direction: column;
        gap: 10px;
    }
}

#containerCadastrar{
    /* padding: 20px; */
    border-radius: 10px;
    width: 90%;
    height: max-content !important;
    margin-top: 20px;
    display: flex;
    align-items: center;
    background-color: var(--ContainerInterno);
    justify-content: center;
}
#containerCadastrar .expensesFieldContainer{
    display: flex;
    align-items: center;
    background-color: var(--ContainerInterno);
    border-radius: 10px;
    justify-content: center;
    flex-wrap: wrap;
    height: max-content;
    max-width: 90vw;
    padding: 5px;
    margin: 0 20px;
    gap: 10px;
}
#containerCadastrar .input::placeholder {
    color: rgba(13, 13, 13, 0.536);
}
#containerCadastrar .field{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#containerCadastrar .input {
    background-color: transparent;
    width: 130px;
    text-align: center;
    height: 20px;
    margin: 5px;
    border: none;
    border-bottom: 1px solid gray;
    color: white;
}

#containerCadastrar select{
    border-radius: 5px;
    cursor: pointer;
    padding: 3px;
}

#containerCadastrar input[type='month'] {
    border-radius: 5px;
    padding: 3px;
    border: none;
    cursor: pointer;
}

#containerCadastrar .btnCadastrar:disabled, #despesas .btnCadastrar:disabled:hover{
    background-image: none;
    background-color: gray;
}

