.table {
    background-color: #eee;
    width: 95%;
    align-self: center;
    margin: 20px 0;
}
.orderBy{
    cursor: pointer;
}
.tableContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.288);
    border-radius: 20px;
    padding: 10px;
}
.functionsRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    padding-right: 20px;

}
.registered{
    width: 50px;
    /* min-width: 50px; */
    white-space: pre-line;
    text-align: center;
}

.labelsRow {
    background-color: var(--schiefBlue);
    color: white;
}

.labelsRow td {
    padding: 8px;
}

.valuesTbody td{
    padding: 5px;
}

.valuesTbody td, .labelsRow td {
    border: 1px solid #ccc;
}