.table{
    background-color: var(--schiefGray);
    width: 100%;
    margin-bottom: 20px;
}
.table th {
    background-color:var(--schiefBlue) ;
    padding: 5px;
    color: white;
}

.thName{
    background-color: var(--schiefRed) !important;
    /* color: var(--schiefRed) !important; */
}

.table tr{
    width: 100%;
    text-align: center;
    border: 1px solid gray;
}

.table td{
    padding: 3px;
    max-width: 250px;
}

.table button {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
    background-color: white;
    height: max-content;
    align-self: center;
    color: var(--schiefGray);
    font-weight: bold;
    font-size: 13px;
}
.paid{
    color: rgba(80, 255, 80, 0.8) !important;
    font-weight: bold;
}
.pending{
    font-weight: bold;
    color: rgba(255, 80, 80, 1) !important;
}

.paid_btn{
    background-color: rgba(80, 255, 80, 0.8) !important;
    color: var(--schiefBlue) !important;
}

.pending_btn{
    background-color: rgba(255, 80, 80, 1) !important;
}

.payment_decription {
    max-width: 100px;
    display: none !important;
}

.table tbody:nth-child(n){
    background-color: rgba(174, 255, 250, 0.3);
}
/* .table tbody:nth-child(2n){
    background-color: rgba(174, 255, 250, 0.6) !important;
}
.table tbody:nth-child(2n+1){
    background-color: rgba(158, 255, 158, 0.6) !important;
} */