#colaboradores #table{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 5px;
    background-color: var(--TabelaFundo);
}

#colaboradores #table th{
    color: var(--TabelaTitulo);
    font-weight: bold;
    border: 1px solid gray;
}
.child_coll_row {
    color: white !important;
    background-color: var(--TabelaTitulo);
    font-size: 15px;
}

.progEditIcon span{
    display: flex;
    justify-content: center;
}

.censoredBar {
    width: 80px;
    height: 10px;
    background-color: black;
}

.addProgression {
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}

.addProgression:hover {
    background-color: var(--TabelaFundo);
}

.progressionRow {
    background-color: white;
}

#colaboradores #table td{
    border: 1px solid gray;
}
#colaboradores #table .tdName {
    width: 50%;
    text-align: start;
    padding: 10px;
}
