.openInFullIcon {
    border: none !important;
    z-index: 555;
    font-size: 16px;
}
.openInFullIcon:hover .tooltip{
    display: inline-block !important;
    font-family: 'Roboto' !important;
}

.numVirtualValues{
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.idField{
    width: fit-content !important;
}
.tooltip {
    font-size: 12px;
    display: none;
    border: none !important;
    position: absolute;
    background-color: #eee;
    padding: 5px;
}

.container:hover .tooltip{
    display: block!important ;
    /* top: 30%; */
    /* left: 30%; */
}

.field:hover .tooltip{
    display: block!important ;
    top: 30%;
    left: 30%;
}

.field {
    position: relative;
}

.editIcon{
    /* transform: translate(20px, 0px); */
}
.deleteIcon{
    /* transform: translate(40px, 0px); */
}
.icon {
    color: var(--schiefBlue);
    border: none !important;
    font-size: 18px;
    /* margin-top: 9px !important; */
    /* margin-right: 10px !important; */
    /* position: absolute; */
    cursor: pointer;
}

.icon:hover .tooltip{
    display: block;
}

.expandedItem{
    display: flex;
    margin-left: 30px !important;
    margin-right: 20px;
    font-size: 12px;
    opacity: 0.9;
    white-space: pre-line;
    align-items: center;
    justify-content: center;
    /* border: none !important; */
}




.labels th{
    width: 100%;
    padding: 3px;
    font-size: 14px;
    background-color: var(--schiefRed);
    color: white;
    border: 1px solid gray;
}

.id {
    width: 50px !important;
}
.pendency{
    min-width: 150px;
}
.tBodyExpanded td{
    text-align: center;
}

.paymentFormContainer{
    z-index: 999;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.editableRow{
    background-color: yellowgreen;
}

.editableRow input {
    text-align: center;
}

.buttonsContainer{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}