#realValue label {
    font-weight: bold;
}
#realValue .container{
    display: flex;
    gap: 10px;
    background-color: var(--TabelaLinhaFundo);
    color: var(--TabelaTexto);
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
    align-items: center;
    justify-content: space-between;
}

#realValue .deleteIcon{
    cursor: pointer;
}

#realValue .row{
    display: flex;
    flex-direction: row;
    gap: 10px;
}