.biggerChart{
    margin-top: 50px;
    width: 100%;
    /* border-radius: 10px; */
    padding: 10px;
    background-color: var(--GraficosFundo);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 0 10px;
    height: max-content;
    /* border: 1px solid var(--schiefRed); */
}

.biggerChart .title{
    color: var(--GraficosTitulo) ;
}
