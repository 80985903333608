.logoutButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 50px;
    background-color: var(--azul);
    cursor: pointer;
    border: 3px solid transparent;
    margin: 30px 0;
    border-radius: 100px;
}
.logoutButton:hover {
    border-color: var(--azul);
    background-color: transparent;
}
.logoutButton:hover .logoutButtonText{
    color: var(--azul);
}
    
.logoutButtonText {
    font-weight: 500;
    transition: all 0.3s;
    color: white;
}