#header {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: var(--HeaderFundo);
}

#header .logo{
    margin: 10px 0;
    width: 150px;
}

#header .minorText{
    font-size: 10px;
    font-style: italic;
    font-weight: bold;
    color: var(--schiefRed);
}