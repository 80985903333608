#valores .table{
    max-width: 100%;
    border-collapse: collapse;
    background-color: var(--TabelaFundo);
    align-self: stretch;
    margin-bottom: 20px;
}

#valores .table th{
    padding: 10px;
    /* color: var(--TabelaTitulo); */
    font-weight: bold;
}

#valores .table td{
    padding: 5px;
    border: 1px solid gray;
}
#valores .table .labels{
    font-weight: 500;
    cursor: pointer;
    padding: 7px;
    text-align: center;
    font-size: 15px;
}

#valores .tagLabel {
    max-width: 100px;
}

#valores .value {
    cursor: pointer;
    font-size: 15px;
    text-align: center;
}

#valores .tdHover:hover .tooltip1{
    display: inline-block;
}

#valores .tdName {
    width: 310px;
    text-align: start;
}

#valores .tdTags {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    row-gap: 5px;
    flex-wrap: wrap;
    border: 0px transparent !important;
}

#valores .pendente{
    background-color: rgba(244, 244, 99, 0.732) !important;
}
#valores .pago{
    background-color: rgba(133, 253, 122, 0.732);
}

