#home .btnsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1000px;
    max-width: 90vw;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 50px 0;
}