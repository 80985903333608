
#metricas .valuesContainer{
    background-color: var(--ContainerInterno);
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    min-width: 1065px;
    max-width: 95vw;
    margin: 20px 0;
}

#metricas .section{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

#metricas .row{ 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#metricas .row .downloadFile{
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    right: 0;
    padding: 5px;
    border-radius: 3px;
    color: var(--schiefBlue);
    background-color: white;

    &:hover{
        cursor: pointer;
        color: var(--schiefRed);
    }
}

#metricas .sectionTitle{
    background-color: var(--TituloContainerInterno);
    color: white;
    height: max-content;
    align-self: center;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

#metricas .topLine{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    gap: 5px;
}

#metricas .valueRow{
    height: 20px;
}

#metricas .value {
    padding: 5px;
}

#metricas .tdName {
    width: 70%;
}

#metricas .tdDate {
    width: 15%;
}
#metricas .tdValue{
    width: 15%;
}

#metricas .entradaRow{
    background-color: rgb(0, 255, 90,0.4);
}
#metricas .saidaRow{
    background-color: rgb(255, 60, 50,0.4);
}
#metricas .urvRow{
    background-color: rgb(255, 255, 0, 0.4);
}

#metricas .periodo {
    background-color: orange;
    border-radius: 5px;
    height: 30px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    align-self: center;
}


#metricas .fullWidthRow{
    min-width: 1065px;
    max-width: 95vw;
}