#usercard img {
    border-radius: 100%;
    width: 30px;
    border: 3px solid var(--schiefRed);
    margin: 0 5px
}
.all_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
#usercard {
    border: 1px solid transparent;
    cursor: pointer;
    margin: 0 20px;
    display: flex;
    align-items: center;
    padding: 5px;
}
#usercard .name {
    color: white;
    text-align: center;
    font-size: 13px;
}

#usercard:hover {
    border-color: var(--schiefRed);
}

.all_container .hidden_menu{
    position: absolute;
    z-index: -1;
    top: 0px;
    opacity: 0;
    width: 86px;
    padding: 0 10px;
    transition: all .6s;
    cursor: pointer;
    border: 2px solid var(--schiefRed);
    background-color: var(--schiefBlue);
    display: flex;
}
.all_container .logout_button{
    padding: 5px;
    width: 80px;
    text-align: center;
    font-weight: bold;
    color: white;
}
.all_container .show {
    z-index: 1;
    top: 50px;
    opacity: 1
}

.all_container .hidden_menu:hover .logout_button{
    color: white;
}