#valuesForm{
    padding: 10px 0;
    border-radius: 10px;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: max-content;
    background-color: var(--ContainerInterno);
    flex-wrap: wrap;
}

#valuesForm .allFieldsCadastrar{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-wrap: wrap;
    height: 100%;
    gap: 10px;
}

#valuesForm .field::placeholder {
    color: rgba(246, 246, 246, 0.536);
}

#valuesForm .field{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
#valuesForm select{
    padding: 3px;
    max-width: 200px;
    text-overflow: ellipsis;
}
#valuesForm .input {
    padding: 3px;
    background-color: transparent;
    width: 130px;
    text-align: center;
    height: 20px;
    margin: 5px;
    border: none;
    border-bottom: 1px solid gray;
    color: white;
}

#valuesForm .btnCadastrar:disabled, #valuesForm .btnCadastrar:disabled:hover{
    background-image: none;
    background-color: gray;
}


.stallments option{
    color: black;
}