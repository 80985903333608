#btnsContainer {
    display: flex;
    /* max-width: 1000px; */
    width: fit-content;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    /* background-color: var(--NavBarFundo); */
    border-radius: 5px;
}

#btnsContainer .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    height: 25px;
    /* width: 20%; */
    /* padding: 15px 20px; */
    margin: 5px;
    border-radius: 5px;

    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;

    color: var(--NavBarBtnsTexto);
    /* background-color: var(--NavBarBtnsFundo); */
    border-bottom: 3px solid transparent;
}

#btnsContainer .btn:hover {
    border-color: var(--NavBarBtnsTexto);
}

#btnsContainer .selected {
    border-color: var(--NavBarBtnsTexto);
}

#btnsContainer .sair{
    color: rgb(251, 116, 116) !important;
    cursor: pointer;
}