#colaboradores .valuesContainer{
    background-color: var(--ContainerInterno);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin: 20px 0;
}

#colaboradores .colabRow, #colaboradores .totalRow{
    background-color: var(--TabelaLinhaFundo)
}

#colaboradores .mainButtonsContainer{
    display: flex;
    gap: 10px;
}

#colaboradores .EDField{
    display: flex;
    align-items: center;
}
#colaboradores .EDIcons{
    font-size: 20px;
    margin: 0 3px;
    cursor: pointer;
}

#colaboradores .totalRow{
    font-weight: 700;
}

#colaboradores .filterContainer {
    margin-top: 10px;
}
#colaboradores .filterInput {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid grey;
    background-color: transparent;
    padding: 5px;
}

