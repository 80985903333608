#virtualValuePage .section{
    background-color: var(--ContainerInterno);
    color: var(--schiefGray);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin: 20px 0;
    font-weight: 300;
}

#virtualValuePage .title{
    font-size: 20px;
    color: var(--Subtitulo);
    font-weight: 500;
    margin-top: 10px;
}

#virtualValuePage .btn{
    margin: 10px;
}

#virtualValuePage label{
    font-weight: 500;
}