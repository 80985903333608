#modal .modalField{
    display: flex;
    justify-content: space-between;
}

#modal .origem input{
 width: 150px;
}

#modal .descricao{
    flex: 1;
    align-items: center;
    display: flex
}

#modal .semestre input {
    width: 80px;
}

#modal .valor input {
    width: 100px;
}

#modal .parcelas input {
    width: 30px;
}

#modal .datadepagamento input {
    width: 80px;
}

#modal .pagoem input{
    width: 80px;
}

#modal .equipe input {
    width: 100px
}

#modal .colaborador input {
    width: 150px;
}

.value{
    z-index: 9999;
}
.value .deleteRValue{
    cursor: pointer;
    z-index: 9999;
}
.openInFullIcon{
    color: var(--schiefBlue);
    border: none !important;
    font-size: 18px;
    margin-top: 9px !important;
    margin-right: 10px !important;
}
.editIcon{
    right: -65px;
    top: -10px;
}
.realEditIcon{
    right: -90px;
    top: -10px;
}
.deleteIcon{
    right: -115px;
    top: -10px;
}
.paymentsTitle, .paymentsExpandedBody, .paymentsSubtitles{
    cursor: default !important;
    background-color: var(--schiefGray) !important;
}
.paymentsExpandedBody:last-child{
    z-index: 10;
}
.paymentsSubtitles{
    color: var(--schiefBlue) !important;
}
.detailIcon{
    right: -40px;
    top: -10px;
}
.editIcon, .detailIcon{
    cursor: pointer;
    display: inline-block;
    align-items: center;
    position: absolute;
    background-color: transparent !important;
    width: auto !important;
    border: none !important;
}
.tooltips{
    display: none;
    position: absolute;
    width: max-content;
    bottom: -35px;
    /* left: -30px; */
    font-size: 13px;
    background-color: white;
    border-radius: 3px;
    padding: 5px;
    z-index: 1;

    &:hover{
        display: none;
    }
}
.tooltip1{
    right: -150px;
    bottom: 0
}
.deleteIcon:hover .tooltip5{
    display: inline-block;
}
.realEditIcon:hover .tooltip4{
    display: inline-block;
}
.editIcon:hover .tooltip2 {
    display: inline-block;
}
.detailIcon:hover .tooltip3 {
    display: inline-block;
}

.tbody{
    position: relative !important;
}
.column{
        width: 100%;
}

.tagContainer{
    display: flex;
    width: fit-content;
    max-width: 100px;
    height: 20px;
    padding: 0px 8px 0px 8px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    margin-bottom: 5px;
}

#modal .dateInput{
width: max-content !important;
text-align: center;
}

