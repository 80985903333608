#colaboradores .valueRow{
    height: 20px;
    cursor: pointer;
}

#modal {
    display: flex;
    flex-direction: column;
}
#modal .submitBtn {
    margin: 0 5px
}
#modal .closeModalBtn{
    background-color: gray;
    color: var(--azul);
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    align-self: center;
    margin: 10px 0;
    font-size: 14px;
}

#modal .modalField {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

#modal .modifyBtnsContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}

#modal .label {
    font-weight: 700;
}

#modal .deleteLabel {
    display: inline-block;
    margin: 5px 10px;
}

#modal .highlight {
    color: rgb(247, 66, 66);
    font-weight: 500;
}

.errorMessage{
    color: red;
    font-size: 13px;
    display: flex;
    justify-content: center;
}