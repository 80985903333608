.container{
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
}

.centerContainer {
    width: 600px;
    display: flex;
    flex-direction: column;
}

.title {
    margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
}

.description {
    font-size: 14px;
}