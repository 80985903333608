#row{
    background-color: var(--TabelaLinhaFundo);
}
#row td{
    border: 1px solid gray;
    color: var(--TabelaTexto);
    padding: 5px;
}
#row :first-child{
    border-left: none;
}
#row :last-child{
    border-right: none;
}