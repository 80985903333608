.title {
    margin: 10px;
    background-color: var(--schiefRed);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px;
    padding: 20px;
    align-items: center;
    border: 1px solid var(--schiefBlue);
}
.feedbackMsg{
    font-size: 13px;
    color: red;
}
.field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.field label{
    margin: 0 10px;
    color: var(--schiefBlue);
    font-size: 14px;
}

.field input {
    border-radius: 20px;
    padding: 10px;
    border: none;
    background-color: var(--schiefBlue);
    color: white;
}
.field input[type=text] {
    text-align: center;
}

.field input[type=button] {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    border: 2px solid transparent;
    
    &:hover{
        border: 2px solid var(--schiefRed) !important;
    }
}

.buttonField{
    justify-content: center;
}