#filter{
    display: flex;
    margin: 10px 0;
    gap: 20px;
    background-color: var(--schiefBlue);
    padding: 15px 40px;
    border-radius: 5px;
    width: fit-content;
}

#filter label {
    color: white;
}