.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-width: 100px;
    width: fit-content;
    height: 30px;
    border-radius: 10px;
    
    background-color: var(--schiefBlue);

    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;

    z-index: 999;
}

.container h1 {
    margin: 10px;
    color: white;
    flex-grow: 1;
    font-size: 14px;
}

.container span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;

    border-right: 1px solid gray;
}

.container .ok{
    color: rgb(0, 185, 25);
}
.container .warning{
    color: yellow;
}
.container .error{
    color: red;
}