#button {
    padding: 10px !important;
    background-color: var(--BtnFundo);
    color: var(--BtnTexto) !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    cursor: pointer !important;
    margin: auto 20px;
}

#button:hover{
    opacity: 0.9;
}

.externo {
    background-color: var(--BtnExternoFundo) !important;
}